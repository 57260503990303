<template>
  <page-main
    title="General Information"
    nextText="Basic Info"
    buttonTabIndex="13"
    eventName="nav"
    @nav="nav('/Information')"
  >
    <template v-slot:aside>
      In this resume-building journey, we begin by getting to know you. You will
      be prompted to supply general information that you would typically see on
      a resume. This section has four parts where you will cover the following:
      <ol>
        <li>
          <b>Basic Information:</b> Standard information found on a resume such
          as name and contact information.
        </li>
        <li>
          <b>Skills & Descriptors:</b> How you describe yourself, your personal
          interests, and your skills.
        </li>
        <li>
          <b>Languages:</b> Any known languages with the option to state your
          fluency.
        </li>
        <li>
          <b>Organizations & Certifications:</b> Your involvement in any
          professional organizations, volunteerism, and/or professional
          licenses/certifications.
        </li>
      </ol>
      If you want to review or edit this section at any time, you can come right
      back to this page and just dive in!
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?" :maxWidth="980">
        <v-container>
          <v-row>
            <v-col cols="12" class="r-col">
              <r-text-field
                v-model="basicInfo"
                label="Basic Info"
                tabindex="1"
                readonly
              ></r-text-field>
            </v-col>
            <v-col cols="12" class="r-col">
              <r-text-field
                v-model="addressAndContact"
                label="Address and Contact"
                tabindex="2"
                readonly
              ></r-text-field>
            </v-col>
            <v-col cols="12" class="r-col">
              <r-text-field
                v-model="leadershipSkills"
                label="Leadership Skills"
                tabindex="3"
                readonly
              ></r-text-field>
            </v-col>
            <v-col cols="12" class="r-col">
              <r-text-field
                v-model="technicalSkills"
                label="Technical Skills"
                tabindex="4"
                readonly
              ></r-text-field>
            </v-col>
            <v-col cols="12" class="r-col">
              <r-text-field
                v-model="descriptorsAdjectives"
                label="Descriptors (Adjectives)"
                tabindex="5"
                readonly
              ></r-text-field>
            </v-col>
            <v-col cols="12" class="r-col">
              <r-text-field
                v-model="descriptorsRoles"
                label="Descriptors (Roles)"
                tabindex="6"
                readonly
              ></r-text-field>
            </v-col>
            <v-col cols="12" class="r-col">
              <r-text-field
                v-model="personalInterests"
                label="Personal Interests"
                tabindex="7"
                readonly
              ></r-text-field>
            </v-col>
            <v-col cols="12" class="r-col">
              <r-text-field
                v-model="languages"
                label="Languages"
                tabindex="8"
                readonly
              ></r-text-field>
            </v-col>
            <v-col cols="12" class="r-col">
              <r-text-field
                v-model="professionalOrganizations"
                label="Professional Organizations "
                tabindex="9"
                readonly
              ></r-text-field>
            </v-col>
            <v-col cols="12" class="r-col">
              <r-text-field
                v-model="volunteerOrganizations"
                label="Volunteer Organizations "
                tabindex="10"
                readonly
              ></r-text-field>
            </v-col>
            <v-col cols="12" class="r-col">
              <r-text-field
                v-model="licensesAndCertifications"
                label="Licenses and Certifications"
                tabindex="11"
                readonly
              ></r-text-field>
            </v-col>
          </v-row>
        </v-container>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          v-for="(item, index) in displayList"
          :key="item.key"
          class="r-col"
        >
          <v-divider></v-divider>
          <status-bar v-model="item.name">
            <div class="d-flex justify-space-between align-center">
              <div>
                <div class="mt-n2">
                  <span class="text-caption">{{ item.title }}</span>
                </div>
                <div class="py-1 text-body-big">{{ item.name }}</div>
              </div>
              <div>
                <div>
                  <v-btn
                    :tabindex="index + 1"
                    tile
                    text
                    @click="nav(item.route)"
                  >
                    Edit
                  </v-btn>
                </div>
              </div>
            </div>
          </status-bar>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import StatusBar from "../components/StatusBar.vue";

// @ is an alias to /src
export default {
  name: "ReviewGeneralInfo",
  data: () => ({
    basicInfo: "John H Doe",
    addressAndContact: "305 Brick Street Albany Apt 202, New York, USA, 519109",
    leadershipSkills:
      "Problem Solving, Communication, Willingness to Learn, Adaptability, Empathy",
    technicalSkills:
      "Excel, PowerPoint, Scenario Analysis, Computer Aided Design (CAD), Financial Planning & Analysis (FP&A), C++, and Python",
    descriptorsAdjectives:
      "Problem Solving, Communication, Willingness to Learn, Adaptability, Empathy",
    descriptorsRoles: "Engineer, Problem Solver, Leader",
    personalInterests: "Winter Sports, Reading, Playing Music, Travel",
    languages: "English (Native); Spanish (Basic)",
    professionalOrganizations:
      "American Society of Mechanical Engineers (ASME) (2013-present)",
    volunteerOrganizations: "Rotaract (2015-2018)",
    licensesAndCertifications: "Six Sigma Greenbelt (2014)",
  }),
  components: {
    "status-bar": StatusBar,
  },
  methods: {
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    arradd(arr, value, route, title, key) {
      arr.push({ name: value, route: route, title: title, key: key });
    },
  },
  computed: {
    displayList: function () {
      let key = 0;

      let x = [];

      this.arradd(x, this.nameDisplay, "/Information", "Basic Info", key++);
      this.arradd(
        x,
        this.addressDisplay,
        "/Contact",
        "Address and Contact",
        key++
      );
      this.arradd(
        x,
        this.softSkillsDisplay,
        "/Softskills",
        "Leadership Skills",
        key++
      );
      this.arradd(
        x,
        this.hardSkillsDisplay,
        "/Hardskills",
        "Technical Skills",
        key++
      );
      this.arradd(
        x,
        this.adjectiveDisplay,
        "/Adjectives",
        "Descriptors (Adjectives)",
        key++
      );
      this.arradd(
        x,
        this.roleDisplay,
        "/Adjectives",
        "Descriptors (Roles)",
        key++
      );
      this.arradd(
        x,
        this.personalInterestsDisplay,
        "/Career",
        "Personal Interests",
        key++
      );
      this.arradd(
        x,
        this.careerInterestsDisplay,
        "/Career",
        "Career Interests",
        key++
      );
      this.arradd(x, this.languagesDisplay, "/Languages", "Languages", key++);
      this.arradd(
        x,
        this.professionalOrgDisplay,
        "/Organizations",
        "Professional Organizations",
        key++
      );
      this.arradd(
        x,
        this.volunteerOrgDisplay,
        "/Organizations",
        "Volunteer Organizations",
        key++
      );
      this.arradd(
        x,
        this.licensesCertsDisplay,
        "/Organizations",
        "Licenses and Certifications",
        key++
      );

      return x;
    },
    ...mapFields("name", { nameDisplay: "displayValue" }),
    ...mapFields("address", { addressDisplay: "displayValue" }),
    ...mapFields("adjective", ["adjectiveDisplay"]),
    ...mapFields("role", ["roleDisplay"]),
    ...mapFields("careerInterest", ["careerInterestsDisplay"]),
    ...mapFields("personalInterest", ["personalInterestsDisplay"]),
    ...mapFields("hardSkill", ["hardSkillsDisplay"]),
    ...mapFields("licenseCert", ["licensesCertsDisplay"]),
    ...mapFields("softSkill", ["softSkillsDisplay"]),
    ...mapFields("language", ["languagesDisplay"]),
    ...mapFields("organization", [
      "professionalOrgDisplay",
      "volunteerOrgDisplay",
    ]),
    tips() {
      return [
        "If this is your first time, start by clicking the button below the text “Next: Basic Info”.",
        "If you are a returning user, use this page to review your content and make any updates.",
      ];
    },
  },
  mounted() {},
};
</script>
